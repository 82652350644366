import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import { PageProps, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const ProfileImage = css`
  img {
    margin: 0;
  }
`;

export const query = graphql`
  query {
    avatar: file(relativePath: { eq: "avatars/avatar.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface AboutPageProps extends PageProps {
  data: {
    avatar: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
}


const About: React.FC<AboutPageProps> = ({ data }) => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '2vw',
            }}>
              <div style={{
                borderRadius: '50%',
                width: '200px',
                height: '200px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Img fluid={data.avatar.childImageSharp.fluid} alt="Profile" css={ProfileImage} style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }} />
              </div>
            </div>
            <PostFullTitle>About Me</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">

              <p>
                I'm a senior software engineer who enjoys building scalable, responsive, mobile-first web applications.
              </p>
              <p>
                In a prior life as a trader, I hedged interest rate risk for mortgage originators
                with mortgage-backed securities. I've also traded commodities, equities, and
                derivatives.
              </p>
              <p>
                When I’m not on the job, I enjoy traveling the world and urban gardening with my
                wife Melody.
              </p>
              <p>
                I have experience working with: TypeScript, React.js, GraphQL, Gatsby.js, MongoDB,
                Express, Node.js, Git, Docker, Webpack, SQL, HTML5/CSS/JS, AWS (S3, EC2, Route 53,
                CloudFront, Lambda), GCP, Google Firebase/Firestore, Jest/Enzyme, Mocha/Chai, Redux,
                jQuery, Heroku, CircleCI, p5.js, SQLite, MySQL, PostgreSQL, Grunt, Python, Scala,
                and Linux.
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;